<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="교재 관리">
            <div class="btn-ibbox title-btnbox float-right">
                <button v-if="view.isAuth==true" @click="pop.showTitle" class="btn-default">교재명 수정</button>
            </div>
            <div v-if="pop.isShow==true">
                <input type="text" v-model="pop.title" class="input-title"><button type="button" @click="pop.doSubmit()" class="btn-default btn-submit">수정</button>
                <div class="clear"></div>
            </div>
            <template v-else>{{ view.info.title }}</template>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-col w-600px">
                        <colgroup>
                            <col style="width:25%">
                            <col style="width:25%">
                            <col style="width:25%">
                            <col style="width:25%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>재고(권)</th>
                                <th>유상(권)</th>
                                <th>무상(권)</th>
                                <th>강사용(권)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ view.info.cnt_qty }}</td>
                                <td>{{ view.info.cnt_paid }}</td>
                                <td>{{ view.info.cnt_unpaid }}</td>
                                <td>{{ view.info.cnt_tutor }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-40 mb-20">
                        <select v-model="view.stype" @change="view.doInit" class="w-100px h-32px float-left">
                            <option value="">입/출/반납 전체</option>
                            <option value="입고">입고</option>
                            <option value="출고">출고</option>
                            <option value="반납">반납</option>
                            <option value="강사출고">강사출고</option>
                            <option value="강사반납">강사반납</option>
                        </select>
                        <button v-if="view.isAuth==true" @click="view.downExcel" class="btn-default float-left ml-10">엑셀 다운로드</button>

                        <button v-if="view.isAuth==true" @click="view.addBook" class="btn-default float-right">입고 등록</button>
                        <button v-if="view.isAuth==true" @click="view.doDelete" class="btn-default float-right mr-20">폐기</button>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="40">
                            <col width="100">
                            <col width="100">
                            <col width="100">
                            <col width="100">
                            <col width="*">
                            <col width="*">
                            <col width="180">
                            <col width="100">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>입/출/반납</th>
                                <th>유/무</th>
                                <th>수량</th>
                                <th>금액</th>
                                <th>출판사</th>
                                <th>구입거래처</th>
                                <th>HQ담당자</th>
                                <th>입/출/반납일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.stype }}</td>
                                <td>{{ irow.paid_txt }}</td>
                                <td>
                                    <span v-if="irow.stype=='입고'" @click="view.modBook(i)" class="btn-view">{{ irow.qty_txt }}</span>
                                    <span v-else>{{ irow.qty_txt }}</span>
                                </td>
                                <td>{{ irow.price_txt }}</td>
                                <td>{{ irow.publisher }}</td>
                                <td>{{ irow.book_seller }}</td>
                                <td>{{ irow.staff_ename + '(' + irow.staff_kname + ')' }}</td>
                                <td>{{ irow.udate }}</td>
                            </tr>
                            <tr v-if="view.total==0">
                                <td colspan="9">등록된 교재 변동사항이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="view.total" :list_per_page="view.rows" v-model="view.page" @change="view.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import { useStore } from 'vuex';


export default {
    layout: 'ResourceManagement',
    components: {
    },
    setup() {
        const router = useRouter();
        const route  = useRoute();
        const toast  = useToast();
        const store  = useStore();

        const pop = reactive({
            idx    : 0,
            title  : "",
            isShow : false,

            showTitle : () => {
                pop.idx = view.idx;
                if( pop.isShow == true ){
                    pop.isShow = false;
                    pop.title  = '';
                    pop.idx    = 0;
                } else {
                    pop.isShow = true;
                    pop.idx    = view.info.idx;
                    pop.title  = view.info.title;
                }
            },

            hideTitle : () => {
                pop.idx = 0;
                pop.isShow = false;
                pop.title  = '';
                view.doSearchInfo();
            },

            doSubmit : () => {
                let params = {
                    idx   : pop.idx,
                    title : pop.title
                };

                if( !params.title ){
                    toast.error("교재명을 입력하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/setBookTitle", params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("수정하였습니다.");
                        pop.hideTitle();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        const view = reactive({
            isAuth    : false,
            isLoaded : false,
            idx   : "",

            page  : 1,
            rows  : 50,
            stype : "",

            info : {
                title      : "",
                cnt_qty    : 0,
                cnt_paid   : 0,
                cnt_unpaid : 0,
                cnt_tutor  : 0,
            },

            list : [], total : 0,

            downExcel : () => {
                location.href = "https://intranet.mxm.kr/excel/textbook_rental_info?idx=" + pop.idx;
            },

            doDelete : () => {
                Swal.fire({
                    title : '폐기',
                    text  : '해당 교재를 폐기하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            idx : view.idx
                        };
                        axios.post("/rest/resourceManagement/delBook", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.error("폐기하였습니다.");
                                view.doInit();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        })
                    }
                })
            },

            addBook : () => {
                sessionStorage.setItem('TextbookMaintenanceAdd', JSON.stringify({
                    btype     : view.info.btype,
                    title     : view.info.title,
                    book_category : view.info.book_category
                }));
                router.push({
                    name : 'ResourceManagement-TextbookMaintenanceAdd'
                });
            },

            modBook : (i) => {
                router.push({
                    name : 'ResourceManagement-TextbookMaintenanceMod-idx',
                    params : {
                        idx : view.list[i].idx_book
                    }
                });
            },

            doInit : () => {
                view.page = 1;
                view.doSearch();
            },

            doSearch : () => {
                if( view.isLoaded == false ) return;
                let params = {
                    idx   : view.idx,
                    page  : view.page,
                    rows  : view.rows,
                    stype : view.stype
                };
                axios.get('/rest/resourceManagement/getBookHistory', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.list  = res.data.list;
                        view.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            doSearchInfo : () => {
                let params = {
                    idx : view.idx
                };
                axios.get('/rest/resourceManagement/getBookInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info  = res.data;
                        view.isLoaded = true;

                        view.doInit();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },


        });


        onMounted(() => {
            // Mounted
            if( store.state.idx_office == 38 ) {
                view.isAuth = true;
            }

            view.idx = route.params.idx;
            if ( !view.idx ) {
                router.go(-1);
            } 

            view.doSearchInfo();
        });

        return {pop, view};
    }
}
</script>

<style lang="scss" scoped>
.input-title {
    width:300px; height:32px; float:left; font-size:14px; border-radius:0; margin-right:-1px;
}
.btn-submit {
    float:left;
}
</style>